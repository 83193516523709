import React, { useEffect, useMemo, useRef, useState } from "react";
import { Bar } from "@visx/shape";
import { Group } from "@visx/group";
import { GradientTealBlue } from "@visx/gradient";
import letterFrequency from "@visx/mock-data/lib/mocks/letterFrequency";
import { scaleBand, scaleLinear } from "@visx/scale";
import { getHistoricalDataOfCoin, getHistoricalHolderData } from "../stream";
import { AxisBottom, AxisLeft } from "@visx/axis";
import { ChartInfo, RangeSelector, SimpleChartChange } from "./simpleChart";
import { theme } from "../theme.styled";
import { borderRads, borderWidths, textSizes } from "../sizes";
import { SmallText } from "../texts.styled";
import { Column, FullRow, Row } from "../containers/flexbox.styled";
import {
  formatNumber,
  getSmallNumber,
  graphFormatter,
} from "../../util/numberFormatter";
import Loader from "../misc/loader";
import { useQuery } from "@tanstack/react-query";
import { BsCaretDownFill, BsCaretUpFill } from "react-icons/bs";
import { Icon } from "../icon.styled";
import { ShowOnMobileWrapper } from "../hideOnMobile";
import CoinRoi from "./coinRoi";
import {
  Tooltip,
  TooltipWithBounds,
  defaultStyles,
  useTooltip,
} from "@visx/tooltip";
import { localPoint } from "@visx/event";
import { FaCaretRight } from "react-icons/fa";

const tooltipStyles = {
  ...defaultStyles,
  background: "rgba(255,255,255,1)",
  border: "1px solid white",
  color: "black",
  zIndex: 1,
};

export default function BarChartWrapper({
  coinId,
  coin,
  isDesktop,
  setHolderCount,
  isHoldersDesktop,
  isHoldersMobile,
  isCompareDesktop,
  isCompareMobile,
}) {
  const [data, setData] = useState(null);

  const [isLoading, setLoading] = useState(false);

  const [changes, setChanges] = useState(null);

  const [isError, setError] = useState(false);

  const [chartData, setChartData] = useState(null);

  const [timeRange, setTimeRange] = useState(null);

  useEffect(() => {
    return () => {
      setChartData(null);
      setData(null);
    };
  }, []);

  const {
    data: queryData,
    status,
    refetch: fetchData,
  } = useQuery(
    ["historic-holder", coinId],
    () =>
      getHistoricalHolderData(coinId, coin.contractAddress, coin.blockChain),
    {
      staleTime: Infinity,
      enabled: false,
      retry: 1,
      onSuccess: (data) => {
        let res = data.holderCounts;
        setTimeRange(data.timeRange);

        console.log("timerange", data.timeRange);

        console.log("holder data", res);

        if (
          res.length === 0 ||
          //all of the holderCounts are 0
          res.every((item) => item.holderCount === 0)
        ) {
          setError(true);
          setLoading(false);
          return;
        }

        if (setHolderCount) setHolderCount(res[0].holderCount);

        let data30Days = res.slice(0, 30);

        setChartData(
          data30Days
            .map((item) => ({
              label: new Date(item.lastUpdated),
              value: item.holderCount,
            }))
            .reverse()
        );

        setData(
          data30Days.map((item) => ({
            label: new Date(item.lastUpdated),
            value: item.holderCount,
          }))
        );
        setLoading(false);

        handleChanges(data30Days, data.timeRange);
      },
      onError: (error) => {
        setError(true);
        setLoading(false);
      },
    }
  );

  function handleChanges(res, tr) {
    let change1, change2, change3, change4;

    console.log("coin", coin);
    console.log("tr", tr);

    if (tr === 30) {
      change1 =
        res.length >= 2 ? res[0].holderCount - res[1].holderCount : null;

      change2 =
        res.length >= 4 ? res[0].holderCount - res[3].holderCount : null;

      change3 =
        res.length >= 8 ? res[0].holderCount - res[7].holderCount : null;

      change4 =
        res.length >= 30 ? res[0].holderCount - res[29].holderCount : null;

      setChanges({
        change1: {
          name: "1d",
          value: change1,
        },
        change2: {
          name: "3d",
          value: change2,
        },
        change3: {
          name: "1w",
          value: change3,
        },
        change4: {
          name: "1m",
          value: change4,
        },
      });
    } else if (tr === 5) {
      change1 =
        res.length >= 2 ? res[0].holderCount - res[1].holderCount : null;

      change2 =
        res.length >= 4 ? res[0].holderCount - res[3].holderCount : null;

      change3 =
        res.length >= 8 ? res[0].holderCount - res[7].holderCount : null;

      change4 =
        res.length >= 30 ? res[0].holderCount - res[29]?.holderCount : null;

      setChanges({
        change1: {
          name: "4h",
          value: change1,
        },
        change2: {
          name: "12h",
          value: change2,
        },
        change3: {
          name: "1d",
          value: change3,
        },
        change4: {
          name: "5d",
          value: change4,
        },
      });
    } else {
      setLoading(false);
      setError(true);
    }
  }

  useEffect(() => {
    setLoading(true);
    if (
      !coin.blockChain ||
      !coin.contractAddress ||
      (coin.blockChain !== "eth" &&
        coin.blockChain !== "base" &&
        coin.blockChain !== "bsc" &&
        coin.blockChain !== "solana" &&
        coin.blockChain !== "tron")
    ) {
      setLoading(false);
      setError(true);
      return;
    }
    fetchData();
  }, []);

  return data && chartData && changes ? (
    <Example
      coin={coin}
      data={chartData}
      isDesktop={isDesktop}
      changes={changes}
      isHoldersDesktop={isHoldersDesktop}
      isHoldersMobile={isHoldersMobile}
      isCompareDesktop={isCompareDesktop}
      timeRange={timeRange}
      isCompareMobile={isCompareMobile}
    />
  ) : !isDesktop ? (
    isLoading ? (
      <div
        style={{
          color: "white",
          width: "100%",
          height: isCompareMobile
            ? "6.5rem"
            : isCompareDesktop
            ? "8.5rem"
            : isHoldersMobile
            ? "8rem"
            : "11rem",
          backgroundColor: "rgba(0, 0, 0, 0.1)",
          borderRadius: "0.5rem",
          border: "0.12rem solid " + theme.colors.OLDLEVEL2,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Loader isSmall={true} />
      </div>
    ) : isError && !isHoldersDesktop ? (
      <CoinRoi id={coinId} coin={coin} />
    ) : null
  ) : isLoading ? (
    <div
      style={{
        color: "white",
        width: "100%",
        height: "9rem",
        backgroundColor: "rgba(0, 0, 0, 0.1)",
        borderRadius: "0.5rem",
        border: "0.12rem solid " + theme.colors.OLDLEVEL2,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Loader isSmall={true} />
    </div>
  ) : isError ? (
    <CoinRoi id={coinId} coin={coin} />
  ) : null;
}

export function Example({
  coin,
  data,
  events = false,
  isDesktop,
  changes,
  isHoldersDesktop,
  isHoldersMobile,
  timeRange,
  isCompareMobile,
}) {
  const [chartData, setChartData] = useState(
    padArray(data, 30, timeRange === 5 ? "four_hours" : "daily")
  );

  const [width, setWidth] = useState(0);
  const height = isCompareMobile
    ? 80
    : isHoldersDesktop
    ? 150
    : isHoldersMobile
    ? 100
    : isDesktop
    ? 114
    : 105;
  const margin = { top: 40, right: -1, bottom: -1, left: -1 };
  // Define the dimensions of the chart area
  const xMax = width - margin.left - margin.right;
  const yMax = height - margin.top - margin.bottom;

  // Define the scales
  const xScale = scaleBand({
    domain: chartData.map((d) => d.label),
    range: [0, xMax],
    padding: 0.2,
  });

  const { tooltipData, tooltipLeft, tooltipTop, showTooltip, hideTooltip } =
    useTooltip();

  const handleTooltip = (event, d) => {
    const { x: xPoint, y: yPoint } = localPoint(event) || { x: 0, y: 0 };
    showTooltip({
      tooltipData: d,
      tooltipLeft: xPoint,
      tooltipTop: yPoint,
    });
    setHoveredBar(d);
  };

  const [hoveredBar, setHoveredBar] = useState(null);

  const handleMouseLeave = () => {
    hideTooltip();
    setHoveredBar(null);
  };

  let minValue =
    chartData.filter((item) => item.isPadded !== true).length === 1
      ? 0
      : Math.min(
          ...chartData
            .filter((item) => item.isPadded !== true)
            .map((d) => d.value)
        );
  let maxValue = Math.max(
    ...chartData.filter((item) => item.isPadded !== true).map((d) => d.value)
  );
  let ratio = maxValue / minValue;

  const yScale = scaleLinear({
    domain: [
      minValue * (1 / ratio),
      Math.max(...chartData.map((d) => d.value)),
    ],
    range: [yMax, 0],
  });

  const containerRef = useRef(null);

  useEffect(() => {
    const updateWidth = () => {
      if (containerRef.current) {
        setWidth(containerRef.current.clientWidth);
      }
    };

    updateWidth();
    window.addEventListener("resize", updateWidth);

    return () => {
      window.removeEventListener("resize", updateWidth);
    };
  }, []);

  const [range, setRange] = useState(0);

  const percentageChange =
    ((data[data.length - 1].value - data[0].value) / data[0].value) * 100;

  return (
    <div
      className="dedicated-coin-chart"
      ref={containerRef}
      style={{ width: "100%", position: "relative" }}
    >
      {/*  <ShowOnMobileWrapper>
        <FullRow
          justify="center"
          style={{
            backgroundColor: "rgba(255, 255, 255, 0.05)",
            padding: "0.5rem",
            borderRadius: "0.5rem 0.5rem 0 0",
            marginBottom: "-0.1rem",
            border: `${borderWidths.SMALL} solid ${theme.colors.OLDLEVEL2}`,
          }}
        >
          <SmallText>Current holders: {formatNumber(data[0].value)}</SmallText>
        </FullRow>
      </ShowOnMobileWrapper> */}

      <RangeSelector
        isHolder={true}
        selected={range}
        setSelected={setRange}
        days={`${timeRange} days`}
      />

      <Row
        gap={"0.2rem"}
        style={{
          position: "absolute",
          left: 0,
          right: 0,
          marginLeft: "auto",
          marginRight: "auto",
          width: "100%",
          justifyContent: "center",
          marginTop: "0.5rem",
        }}
      >
        {isHoldersMobile ? (
          <>
            <img
              style={{ width: "1.2rem", marginRight: "0.2rem" }}
              src={coin.image}
            />
            <SmallText>
              Current: {formatNumber(data[data.length - 1].value)}
            </SmallText>
          </>
        ) : (
          <>
            <img
              style={{ width: "1.2rem", marginRight: "0.2rem" }}
              src={coin.image}
            />
            <SmallText>{coin.symbol.toUpperCase()} Holders</SmallText>
          </>
        )}
      </Row>

      <SimpleChartChange isHolder={true} isProfit={percentageChange >= 0}>
        {data.length > 1 ? (
          <Row gap="0.25rem">
            {Math.abs(percentageChange) === 0 ? (
              "0"
            ) : Math.abs(percentageChange) > 0.001 ||
              getSmallNumber(percentageChange, true).zeroCount === 0 ? (
              graphFormatter(percentageChange, false, true).replace("-", "")
            ) : (
              <span>
                <span>0.0</span>
                <span
                  style={{
                    fontSize: textSizes.fontSizes.MOBILE_LITTLE_NUMBER,
                    fontWeight: "500",
                  }}
                >
                  {getSmallNumber(percentageChange, true).zeroCount}
                </span>
                <span>
                  {getSmallNumber(percentageChange, true).nonZeroDigits[0]}
                </span>
              </span>
            )}
            %
            <Icon>
              {percentageChange >= 0 ? (
                <Icon color={theme.colors.GREEN}>
                  <BsCaretUpFill />
                </Icon>
              ) : (
                <Icon color={theme.colors.RED}>
                  <BsCaretDownFill />
                </Icon>
              )}
            </Icon>
          </Row>
        ) : (
          "-"
        )}
      </SimpleChartChange>
      <svg
        style={{
          border: "0.12rem solid " + theme.colors.OLDLEVEL2,
          borderRadius: `${borderRads.SMALL} ${borderRads.SMALL} 0 0`,

          //filter: "drop-shadow(0px 0px 7px rgba(130, 221, 85, 0.4))",
        }}
        width={width}
        height={height}
      >
        <rect
          width={width}
          height={height}
          fill={isDesktop ? "rgba(0, 0, 0, 0.1)" : theme.colors.SECONDARY}
        />

        <Group top={margin.top} left={margin.left}>
          {chartData.map((d) => {
            const isHovered = hoveredBar === d;
            return (
              <Bar
                key={d.label}
                x={xScale(d.label)}
                y={yScale(d.value)}
                width={xScale.bandwidth()}
                height={yMax - yScale(d.value)}
                fill={
                  isHoldersDesktop
                    ? "rgb(40 200 220 / 16%)"
                    : isHovered
                    ? "#8c2c91"
                    : "#711a75"
                }
                onMouseMove={(event) => handleTooltip(event, d)}
                onMouseLeave={handleMouseLeave}
              />
            );
          })}
        </Group>
      </svg>
      {tooltipData && (
        <TooltipWithBounds
          top={tooltipTop}
          left={tooltipLeft}
          style={tooltipStyles}
        >
          <Column align="start" gap="0.5rem">
            <SmallText weight={textSizes.fontWeights.SEMI_BOLD} color="black">
              {formatNumber(tooltipData.value)}
            </SmallText>
            <SmallText color={"#848484"}>
              {coin.blockChain === "solana" ||
              coin.blockChain === "tron" ||
              (coin.blockChain === "eth" && coin.tags?.includes("Meme"))
                ? tooltipData.label.toLocaleString()
                : tooltipData.label.toLocaleDateString("en-US", {
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                  })}
            </SmallText>
          </Column>
        </TooltipWithBounds>
      )}
      <ChartInfo>
        <Row gap="0.5rem">
          <SmallText
            weight={textSizes.fontWeights.SEMI_BOLD}
            color={theme.colors.LIGHT_TEXT}
          >
            {changes.change1.name}:{" "}
          </SmallText>
          <SmallText>
            <Row gap="0.2rem">
              {changes.change1.value && changes.change1.value >= 0 && "+"}
              {changes.change1.value ? (
                <>
                  {graphFormatter(
                    changes.change1.value,
                    false,
                    true,
                    false,
                    true
                  )}
                  {changes.change1.value >= 0 ? (
                    <Icon color={theme.colors.GREEN}>
                      <BsCaretUpFill />
                    </Icon>
                  ) : (
                    <Icon color={theme.colors.RED}>
                      <BsCaretDownFill />
                    </Icon>
                  )}
                </>
              ) : changes.change1.value === 0 ? (
                <Icon color={theme.colors.LIGHT_TEXT}>
                  <FaCaretRight />
                </Icon>
              ) : (
                "-"
              )}
            </Row>
          </SmallText>
        </Row>
        <Row gap="0.5rem">
          <SmallText
            weight={textSizes.fontWeights.SEMI_BOLD}
            color={theme.colors.LIGHT_TEXT}
          >
            {changes.change2.name}:{" "}
          </SmallText>
          <SmallText>
            <Row gap="0.2rem">
              {changes.change2.value && changes.change2.value >= 0 && "+"}
              {changes.change2.value ? (
                <>
                  {graphFormatter(
                    changes.change2.value,
                    false,
                    true,
                    false,
                    true
                  )}
                  {changes.change2.value >= 0 ? (
                    <Icon color={theme.colors.GREEN}>
                      <BsCaretUpFill />
                    </Icon>
                  ) : (
                    <Icon color={theme.colors.RED}>
                      <BsCaretDownFill />
                    </Icon>
                  )}
                </>
              ) : changes.change2.value === 0 ? (
                <Icon color={theme.colors.LIGHT_TEXT}>
                  <FaCaretRight />
                </Icon>
              ) : (
                "-"
              )}
            </Row>
          </SmallText>{" "}
        </Row>
        <Row gap="0.5rem">
          <SmallText
            weight={textSizes.fontWeights.SEMI_BOLD}
            color={theme.colors.LIGHT_TEXT}
          >
            {changes.change3.name}:{" "}
          </SmallText>
          <SmallText>
            <Row gap="0.2rem">
              {changes.change3.value && changes.change3.value >= 0 && "+"}
              {changes.change3.value ? (
                <>
                  {graphFormatter(
                    changes.change3.value,
                    false,
                    true,
                    false,
                    true
                  )}
                  {changes.change3.value >= 0 ? (
                    <Icon color={theme.colors.GREEN}>
                      <BsCaretUpFill />
                    </Icon>
                  ) : (
                    <Icon color={theme.colors.RED}>
                      <BsCaretDownFill />
                    </Icon>
                  )}
                </>
              ) : changes.change3.value === 0 ? (
                <Icon color={theme.colors.LIGHT_TEXT}>
                  <FaCaretRight />
                </Icon>
              ) : (
                "-"
              )}
            </Row>
          </SmallText>{" "}
        </Row>
        <Row gap="0.5rem">
          <SmallText
            weight={textSizes.fontWeights.SEMI_BOLD}
            color={theme.colors.LIGHT_TEXT}
          >
            {changes.change4.name}:{" "}
          </SmallText>
          <SmallText>
            <Row gap="0.2rem">
              {changes.change4.value && changes.change4.value >= 0 && "+"}
              {changes.change4.value ? (
                <>
                  {graphFormatter(
                    changes.change4.value,
                    false,
                    true,
                    false,
                    true
                  )}
                  {changes.change4.value >= 0 ? (
                    <Icon color={theme.colors.GREEN}>
                      <BsCaretUpFill />
                    </Icon>
                  ) : (
                    <Icon color={theme.colors.RED}>
                      <BsCaretDownFill />
                    </Icon>
                  )}
                </>
              ) : changes.change4.value === 0 ? (
                <Icon color={theme.colors.LIGHT_TEXT}>
                  <FaCaretRight />
                </Icon>
              ) : (
                "-"
              )}
            </Row>
          </SmallText>{" "}
        </Row>
      </ChartInfo>
    </div>
  );
}

function padArray(data, targetLength, intervalType) {
  // Create a deep copy of the original array
  let newData = JSON.parse(JSON.stringify(data));

  // Convert the labels to Date objects
  newData.forEach((item) => {
    item.label = new Date(item.label);
  });

  // Define the interval in seconds based on the intervalType
  let interval;
  if (intervalType === "four_hours") {
    interval = 4 * 60 * 60;
  } else if (intervalType === "daily") {
    interval = 24 * 60 * 60;
  } else {
    throw new Error("Interval should be 'four_hours' or 'daily'");
  }

  // Calculate the start date based on the first data point and the interval
  let firstDate = newData[0].label;
  let startDate = new Date(
    firstDate.getTime() - interval * 1000 * (targetLength - newData.length)
  );

  // Adjust the start date if the first data point is not later by one interval
  if (newData[0].label.getTime() < startDate.getTime() + interval * 1000) {
    startDate = new Date(newData[0].label.getTime() - interval * 1000);
  }

  // Generate the padded section
  let paddedSection = [];
  while (paddedSection.length < targetLength - newData.length) {
    paddedSection.push({
      label: new Date(startDate),
      value: 0,
      isPadded: true,
    });
    startDate = new Date(startDate.getTime() + interval * 1000);
  }

  // Combine the padded section with the original data
  let paddedData = [...paddedSection.reverse(), ...newData];

  return paddedData;
}
