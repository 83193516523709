import { StyledNavbar } from "./navbar.styled";
import { Paragraph, LightHeader, SmallText } from "../texts.styled";
import { Row } from "../containers/flexbox.styled";
import { BiSolidGasPump } from "react-icons/bi";
import { Icon } from "../icon.styled";
import SearchBar from "../search/searchBar";
import { HideOnMobileWrapper, ShowOnMobileWrapper } from "../hideOnMobile";
import MobileNavMenuButton from "../buttons/mobileNavMenuButton";
import MobileSearchButton from "../buttons/mobileSearchButton";
import React, { useEffect, useState } from "react";
import MobileNavMenu from "../menus/MobileNavMenu";
import { RouteLink } from "../../routing/routeLink";
import { useQuery } from "@tanstack/react-query";
import MobileSearchMenu from "../menus/MobileSearchMenu";
import { MostComparedContext, TrendsContext } from "../../context";
import { useMediaQuery } from "react-responsive";
import NavbarCoinInfo from "./navbarCoinInfo";
import { HalvingNavbar, HalvingNavbarWrapper } from "../halving/halving.styled";
import { IoIosArrowForward } from "react-icons/io";
import { textSizes } from "../sizes";
import { getHalvingDate } from "../stream";
import { NavbarPrimaryButton } from "../buttons/buttons.styled";
import { dateDifferenceInDays } from "../halving/halvingResultThree";
import NavbarDreamfolio from "../portfolio/navbarPortfolio";
import { useLocation } from "react-router-dom";

export default function Navbar() {
  const [isMobileNavMenuOn, setMobileNavMenuOn] = useState(false);
  const [isMobileSearchMenuOn, setMobileSearchMenuOn] = useState(false);
  const [difference, setDifference] = useState(null);

  const isMobile = useMediaQuery({
    query: "(max-width: 70rem)",
  });

  useEffect(() => {
    if (isMobileSearchMenuOn || isMobileNavMenuOn) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "visible";
    }
    return () => {
      document.body.style.overflow = "visible";
    };
  }, [isMobileSearchMenuOn, isMobileNavMenuOn]);

  // const { data: halvingDate, status: halvingDateStatus } = useQuery(
  //   ["halving-date"],
  //   () => getHalvingDate(),
  //   {
  //     refetchOnWindowFocus: false,
  //     onSuccess: (data) => {
  //       setDifference(dateDifferenceInDays(new Date(data), new Date()));
  //     },
  //   }
  // );

  const { pathname } = useLocation();

  return (
    <StyledNavbar noBg={pathname.includes("dreamfolio")}>
      <Row align="center" gap="1.5rem">
        <RouteLink to={"/"}>
          <img
            style={{ width: "2rem" }}
            src="/logos/comparemarketcap-logo.svg"
            alt="cmc"
          />
        </RouteLink>

        {!pathname.includes("dreamfolio") && (
          <RouteLink style={{ marginLeft: "0rem" }} to={"/dreamfolio"}>
            <ShowOnMobileWrapper>
              <HalvingNavbarWrapper gap="0.5rem">
                <SmallText
                  color={({ theme }) => theme.colors.GREEN}
                  weight={textSizes.fontWeights.BOLD}
                  style={{ marginRight: "0.5rem" }}
                  size={"0.7rem"}
                >
                  NEW
                </SmallText>
                <SmallText
                  style={{
                    whiteSpace: "nowrap",
                  }}
                  size={textSizes.fontSizes.SMALLER}
                >
                  DREAMFOLIO
                </SmallText>
                <Icon color="#42a4ff">
                  <IoIosArrowForward />
                </Icon>
              </HalvingNavbarWrapper>
            </ShowOnMobileWrapper>
          </RouteLink>
        )}

        <HideOnMobileWrapper>
          <NavbarCoinInfo />
        </HideOnMobileWrapper>
      </Row>

      <HideOnMobileWrapper style={{ width: "100%", maxWidth: "30rem" }}>
        <SearchBar />
      </HideOnMobileWrapper>

      <HideOnMobileWrapper>
        <Row style={{ minWidth: "38rem" }} gap="3rem" justify="end">
          <NavbarDreamfolio />
          <RouteLink to={"/promote"}>
            <NavbarPrimaryButton
              backgroundColor={({ theme }) => theme.colors.BLUE}
              hoverColor={({ theme }) => theme.colors.BLUE2}
            >
              <LightHeader>GET LISTED</LightHeader>
            </NavbarPrimaryButton>
          </RouteLink>
          <RouteLink to={"/halving"}>
            <LightHeader>HALVING REPORT</LightHeader>
          </RouteLink>
        </Row>
      </HideOnMobileWrapper>

      <ShowOnMobileWrapper>
        <Row align="center" gap="2rem">
          {!pathname.includes("dreamfolio") && (
            <MobileSearchButton onClick={() => setMobileSearchMenuOn(true)} />
          )}
          <MobileNavMenuButton onClick={() => setMobileNavMenuOn(true)} />
        </Row>
      </ShowOnMobileWrapper>

      {isMobile && (
        <MobileNavMenu
          isOn={isMobileNavMenuOn}
          onClose={() => setMobileNavMenuOn(false)}
        />
      )}

      {isMobile && (
        <MobileSearchMenu
          isOn={isMobileSearchMenuOn}
          onClose={() => setMobileSearchMenuOn(false)}
        />
      )}
    </StyledNavbar>
  );
}
