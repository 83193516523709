import styled from "styled-components";
import { borderRads, borderWidths, textSizes } from "../sizes";
import { paddings } from "../paddings.styled";
import { Paragraph } from "../texts.styled";
import { Icon } from "../icon.styled";
import { Column, FullRow, Row } from "../containers/flexbox.styled";

export const TransparentButton = styled.button`
  color: ${({ theme, color }) => color || theme.colors.TEXT};
  width: ${({ width }) => width && width};

  background-color: transparent;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${({ padding }) => padding || 0};
  border-radius: ${borderRads.SMALL};
  transition: 0.3s;

  &:hover {
    cursor: pointer;
    background-color: ${({ theme, hoverable, hoverColor }) =>
      hoverable ? hoverColor || theme.colors.LEVEL : "transparent"};
    transform: ${({ rotateonhover }) => rotateonhover && "rotate(180deg)"};
  }
`;

export const PrimaryButton = styled.button`
  color: ${({ theme, color }) => color || theme.colors.TEXT};
  font-weight: ${({ weight }) => weight && weight};

  border-radius: ${({ borderRadius }) => borderRadius || borderRads.SMALL};
  border: none;
  background-color: ${({ theme, backgroundColor }) =>
    backgroundColor || theme.colors.BRAND};
  width: ${({ isfull, width }) => (isfull ? "100%" : width)};
  padding: ${paddings.HIGH} ${paddings.HIGH};
  font-size: ${textSizes.fontSizes.MEDIUM};

  filter: ${({ isFake }) => isFake && "grayscale(70%)"};

  &:hover {
    background-color: ${({ theme, hoverColor, isFake }) =>
      !isFake ? (hoverColor ? hoverColor : theme.colors.BRAND_HOVER) : "auto"};
  }

  transition: 0.4s;
`;

export const NavbarPrimaryButton = styled(PrimaryButton)`
  padding: ${paddings.LOW} ${paddings.HIGH};
`;

export const OutlineButton = styled.button`
  color: ${({ theme, color }) => color || theme.colors.TEXT};
  font-weight: ${({ weight }) => weight && weight};

  border-radius: ${borderRads.SMALL};
  border-width: ${({ borderWidth }) => borderWidth || borderWidths.SMALL};
  border-radius: ${({ borderRadius }) => borderRadius || borderRads.SMALL};
  border-color: ${({ borderColor, theme }) =>
    borderColor || theme.colors.BRAND};
  border-style: solid;
  background-color: transparent;
  width: ${({ isfull, width }) => (isfull ? "100%" : width)};
  padding: ${paddings.HIGH} ${paddings.HIGH};
  font-size: ${textSizes.fontSizes.MEDIUM};

  &:hover {
    background-color: ${({ theme, hoverColor }) =>
      hoverColor ? hoverColor : theme.colors.BRAND};
  }

  transition: 0.4s;
`;

export const CopyMessage = styled(Paragraph)`
  width: 4rem;
  display: ${({ isOn }) => (isOn ? "block" : "none")};
  position: absolute;
  right: ${({ isRight }) => (isRight ? "-4.5rem" : "100%")};
  top: 0rem;
  padding: ${paddings.LOW};
  background-color: black;
  border: ${borderWidths.SMALL} solid ${({ theme }) => theme.colors.LEVEL};
  font-size: ${textSizes.fontSizes.SMALLER};
  border-radius: ${borderRads.SMALL};
  text-align: center;
`;

export const GraphModeButton = styled.button`
  padding: ${paddings.LOW} ${paddings.MID};
  background-color: ${({ selected, theme }) =>
    selected ? theme.colors.BRAND : "transparent"};
  border: ${borderWidths.SMALL} solid ${({ theme }) => theme.colors.BRAND};
  border-radius: ${borderRads.SMALL};
  font-weight: ${({ selected }) => selected && textSizes.fontWeights.SEMI_BOLD};
`;

export const GraphCoinButtons = styled(FullRow)`
  justify-content: start;
  align-items: center;
  gap: 1rem;
  align-self: start;
  padding: ${paddings.LOWER};
`;

export const GraphCoinButton = styled(Column)`
  background-color: ${({ first }) =>
    first ? "transparent" : "rgba(255,255,255,0.15)"};
  align-items: center;
  border-radius: ${borderRads.SMALL};
  img {
    width: 1.2rem;
  }
  ${Row} {
    gap: 0.5rem;
    padding: ${paddings.LOW};
  }
`;

export const GraphCoinButtonColor = styled.div`
  background-color: ${({ color }) => color};
  width: 50%;
  height: 0.2rem;
`;
